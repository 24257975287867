@import "./../../../styles/variables";
@import "./../../../styles/mixin";
#we-tag-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: $spaceBetween45 0;
    .we-tag-wrapper {
        display: flex;
        flex-direction: column;
        gap: $spaceBetween;

        
        .dig {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .text-wrapper {
                width: 100%;
                max-width: 45rem;
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 0 30px 0 30px;

                label {
                    @include textBasis($title);
                }
            }
            .button-container {
                a {
                    text-decoration: none;
                    color: white;
                    z-index: 1;
                }
            }
        }
        
    }
}

@include media-md {
    #we-tag-wrapper {
        .we-tag-wrapper {
            
            .dig {
                flex-wrap: wrap;
                .text-wrapper {
                    
                    label {
                        @include textBasis($title);
                        text-align: center;
                    }
                }            
            }            
        }
    }
}