@import "./../../../styles/main";

.single-card{
    display: flex;
    min-height: 290px;
    margin-top: $spaceBetween45;
    align-items: center;
    
    .history-point{
        display: flex;
        flex-direction: column;
        .card{
            display: flex;
            flex-direction: column;
            width: 225px;
            height: 268px;
            box-shadow: 0px 0px 8px 4px rgba($BorderBlack,0.6);
            border-radius: $borderRadiusMain;
            background-color: white;
            z-index: 1;
            padding: 13px;
            transition: height ease-in 1s;
            .card-header{  
                display: flex;
                flex-direction: column;
                margin-top: -37px;
                margin-bottom: 9px;
                align-self: center;
                width: 173px;
                height: 49px;
                border-radius: $borderRadiusMain;
                background-color: $AmarilloBanner;
                label{
                    align-self: center;
                    width: 41px;
                    height: 13px;
                    @include textBasisBold($text);
                    margin: 10px 0;
                }
                .sphere2{
                    align-self: center;
                    width: 17px;
                    height: 17px;
                    border-radius: $borderRadiusCircle-25;
                    background-color: $SeaSerpent;
                    margin-bottom: -14px;
                }
            }
            .card-text{
                height: 100%;
                display: flex;
                text-align: center;
                @include textBasis($text);
                label{
                    transition: opacity ease-in 1.5s;
                    align-self: center;
                }
                &.bold{
                    font-weight: bold;
                }
            }
        }
        .sub-card{
            display: flex;
            flex-direction: column;
            height: 340px;
            transition: height ease 1s;
            opacity: 1;
            .card-sub-conect{
                height: 60px;
                width: 10px;
                background-color: $OH-orange;
                box-shadow: 0px 13px 7px 0px $BorderBlack;
                border-radius: $borderRadiusMain;
                z-index: 2;
                margin: -8px 0;
                align-self: center;
            }
        }
        .sub-card-no{
            .card-sub-conect{
                height: 0;
                min-height: 0;
            }
            .card{ 
                height: 0;
                min-height: 0;
                .card-text{ 
                    height: 0;
                    min-height: 0;
                    opacity: 0;
                    label{
                        transition: opacity ease-in 0.1s;
                        opacity: 0;
                        height: 0;
                    }
                }
            }
            opacity: 0;
            height: 0;
            transition: all ease-in 0.5s;
        }
    }
    .card-conect{
        height: 5px;
        width: 75px;
        background-color: $OH-orange;        
        z-index: 2;
    }
}

