@import "../../styles/main";

.projects{
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: center;
    margin-top: $spaceBetween;
    padding: 0 $mainLGRightLeftPadding;
    margin-bottom: $mainLGRightLeftPadding;
    gap: $spaceBetween;
    .textwrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: $spaceBetween;
        .title {
            @include textBasis($title);
        }
        .desc{
            @include textBasis($text);
            margin-top: 10px;
            max-width: 768px;
            text-align: center;

        }
    }
    .project-card-wrapper{
        align-self: center;
        display: grid;
        grid-template-rows:  277px 277px 277px; 
        grid-template-columns: 282px 282px 282px 282px 282px; 
        opacity: 0;
        animation: AppearSequence 0.5s linear forwards;
    }

}

@include media-xl{
    .projects{
        .project-card-wrapper{
            grid-template-rows: 277px 277px 277px; 
            grid-template-columns: 282px 282px 282px 282px; 
        }
    }
}


@include media-lg{
    .projects{
        .project-card-wrapper{
            grid-template-rows: 277px 277px 277px 277px; 
            grid-template-columns: 282px 282px 282px; 
        }
    }
}

@include media-md{
    .projects{
        .project-card-wrapper{
            grid-template-rows: 277px 277px 277px 277px  ; 
            grid-template-columns: 282px 282px; 
        }
    }
}
@include media-sm{
    .projects{
        .project-card-wrapper{
            grid-template-rows: 277px 277px 277px 277px 277px 277px 277px 277px 277px 277px 277px ; 
            grid-template-columns: 282px; 
        }
    }
}


@keyframes AppearSequence {
    0% {
        opacity: 0;
        gap: 0;
	}
	100% {
        opacity: 1;
        gap: 40px;
	}
}