$White: #fff;
$Red: red;
$Orange: orange;
$Green: green;
$Grey: rgb(61, 61, 61);

$CeriseRed: #e5405d;
$PineTree: #2d2829;
$Black: #000;
$SeaSerpent: #4ac1c6;
$MinionYellow: #FAD352;
$DarkLavender: #5a4e7d;
$aqua:#abe3e5;
$Shadow: #eaebea;

$AmarilloBoton: #fad352;
$AmarilloBanner: #f5ee78;
$LilaBanner: #5e4999;

$OH-orange: #f4ac40;

$darkerPorcentaje: 20%;

$ratio : 5rem;

//BORDES
$BorderBlack: rgba(#181718, 1);
$BorderWidth: 2px;

$borderRadiusMain: 6px;
$borderRadiusCircle-25: 25px;

//MEDIDAS
$mobile: 480px;
$tablet: 780px;
$tabletLandcape: 1024px;
$laptop: 1600px;
$desktop: 1600px;

//ESPACIOS
$spaceBetween: 60px;
$spaceBetween45: 45px;
$spaceBetween20: 20px;

$mainLGRightLeftPadding: 70px;
$mainMDRightLeftPadding: 35px;
$mainSMRightLeftPadding: 18px;

// - Paddins cajas

$paddinCajas: 12px;

$paddingCajasLG: 42px;
$paddingCajasMD: 30px;
$paddingCajasSM: 30px;

// - margins cajas

$marginCajasLG: 42px;
$marginCajasMD: 30px;
$marginCajasSM: 30px;


//BUTTONS
$btnBorderRadius: 4px;

//LG
$btnLgHeight: 50px;
$btnLgTxtSize: 1.188rem;

//MD
$btnMdHeight: 69px;
$btnMdTxtSize: 1.625rem;

//title
$title: 1.65rem;
$subtitle: 1.25rem;
$text: 0.875rem;
$pageTitle: 3.2rem;
$pageSubtitle:1.188rem;