@import "../../../../styles/variables";
.slider-wrapper{
    width: 100%;
    .slider-field{
        .slider-value{
            width: 65px;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        input[type=range] {
            border:none;
            height: 25px;
            -webkit-appearance: none;
            margin: 10px 0;
            width: 100%;
            padding: 0;
            background: transparent;
        }
        //La barrita
        input[type=range]::-webkit-slider-runnable-track {
            width: 100%;
            height: 2px;
            cursor: pointer;
            background: $Black;
            border-radius: $borderRadiusMain;
        }
        //La Pelotita
        input[type=range]::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: 1px solid $Black;
            height: 18px;
            width: 18px;
            border-radius: $borderRadiusCircle-25;
            background: $CeriseRed;
            cursor: pointer;
            margin-top: -9px;
        }
       
    }
}