@import "./styles/variables";

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.app {
  height: 100%;
  background-color: $White;
  //background: linear-gradient(180deg, rgba($DarkLavender,1) 0 50%, rgba(255,255,255,1) 50% 100%);
  //background: -webkit-linear-gradient(top, rgba($DarkLavender,1) 0 50%, rgba(255,255,255,1) 50% 100%);
  .grid {
    //padding-top: 93px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

#container{
  width: 100%;
  //margin-top: 60px;
}
