@import "../../styles/mixin";

.img-tech-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .selected,.no-selected {
        width: 55px;
        height: 55px;
        margin: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            cursor: pointer;
            width: 100%;
        }
    }
    .no-selected{        
        img{
            filter: grayscale(100%);
        }
    }
}

@include media-md{
    .img-tech-wrapper{
        .no-selected{
            width: 17%;
            margin: 8px;
        }
        .selected{
            width: 17%;
            margin: 8px;
        }
    }
}

@include media-sm{
    .img-tech-wrapper{
        .no-selected{
            width: 20%;
            margin: 8px;
        }
        .selected{
            width: 20%;
            margin: 8px;
        }
    }
}