@import "../../../styles/mixin";
@import "../../../styles/variables";

.what-do-we-do {
    display: flex;
    flex-direction: column;
    grid-row: 3;
    gap: 34px;
    margin-bottom: $spaceBetween45;
    height: 360px;
    align-items: center;
    width: 100%;
    position: relative;
    
    &::before{
        position: absolute;
        @include backgroundPattern($AmarilloBanner,0.6,$OH-orange);
        content: " ";
        height: 100%;
        top: 0;
        width: 100%;
    }
    .max-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
    }
    .title {
        display: flex;
        flex-direction: column;
        padding-top: 17px;
        text-align: center;

        margin-bottom: 3em;
        @include textBasis($text);
        :first-child {
            text-align: center;
            @include textBasisBold($title);
        }
    }
    .cards-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: -50px;
        padding-bottom: 50px;
        overflow: hidden;
        .card {
            height: 307px;
            width: 275px;
            background-color: #ffffff;
            box-shadow: 0px 23px 35.7px 2.3px rgba($BorderBlack, 0.15);
            border-radius: $borderRadiusMain;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 15px 0;
            text-align: center;
            opacity: 0;
            margin-top: 80px;
            text-decoration: none;
            transition: all ease 0.3s;
            
            &:hover {
                transition: all ease 0.3s;
            }
            button {
                margin-top: 5px;
                background-color: $CeriseRed;
            }
            button:hover {
                background-color: darken($CeriseRed, $darkerPorcentaje);
            }
            label {
                width: 216px;
                @include textBasisBold($text);
            }
            p {
                width: 216px;
                @include textBasis($text);
            }
            .img-wrapper {
                height: 70px;
                width: 100%;
                img {
                    height: 100%;
                }
            }
        }
    }
}

@include media-max-lg {
    .what-do-we-do {
        //height: 700px; // Para alineacion 3-1
    }
}
@include media-md {
    .what-do-we-do {
        border-bottom: unset;
        border-top: unset;
        background-color: $White;
        .title {
            :nth-child(2) {
                width: 100%;
                padding: 0 20px;
                align-self: center;
            }
        }
        .cards-wrapper {
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: nowrap;
            align-items: center;
            padding-bottom: 61px;
            width: 100%;
            overflow-x: scroll;
            .card {
                justify-content: space-around;
                width: 242px;
                height: 307px;
                margin: 0 15px;
                box-shadow: 0px 10px 10px 2.3px rgba($BorderBlack, 0.15);
                label {
                    width: 100%;
                    font-size: 1.5rem;
                }
                button {
                    width: 295px;
                }
                &.animation-showup{
                    animation: showLeft 1s ease forwards;
                }
            }
        }
    }
}

@include media-sm {
    .what-do-we-do {
        .cards-wrapper {
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            width: 100%;
            .card {
                //max-width: 382px;
                width: 242px;
                margin: 0 15px;
                height: 307px;
                label {
                    //width: 295px;
                    font-size: 1.5rem;
                }
                button {
                    width: 295px;
                    margin: 10px $paddingCajasSM;
                }
            }
        }
    }
}
