@import "../../../styles/mixin";
@import "../../../styles/variables";
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:700);

#meetup {
  position: absolute;
  top: 10vh;
  padding: 30px 0 30px 30px;
  background: transparent;
  width: 180px;
  height: 300px;
  border-radius: $btnBorderRadius 0 0 $btnBorderRadius;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 9999;
  #image {
    width: 100px;
    height: 100px;
    //background-image: url("../../../assets/img/angular.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
  }
  #text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      @include textBasis($text);
      text-align: center;
      color: white;
      margin-bottom: 10px;
    }
    &.animation-showdown {
      animation-delay: 1s;
    }
  }

  .button-container {
    margin: 0;

    &::after {
      background-color: $MinionYellow;
    }
    a {
      text-decoration: none;
      color: white;
      z-index: 1;
      display: flex;
      align-items: center;
    }
    &:hover {
      a {
        color: $DarkLavender;
      }
    }
  }
}


