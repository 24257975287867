@import "./../../../styles/main";
@import "./../../../styles/variables";

#where-name-come-from {
    display: flex;
    justify-content: center;
    .where-name-come-from {
        display: flex;
        gap: 5%;
        width: 100%;
        justify-content: space-between;
        padding-bottom: $spaceBetween45;

        .text-wrapper,
        .img-wrapper {
            display: flex;
            justify-content: center;
            position: relative;
            border-radius: $borderRadiusMain;
            box-shadow: 0px 6px 22.7px -2.7px rgb(24 23 24 / 15%);
        }

        .text-wrapper {
            width: 60%;
            //max-width: 45rem;
            flex-direction: column;
            align-items: flex-start;
            padding: $spaceBetween45;
            background: white;

            .title {
                @include textBasisBold($title);
            }
            .textpart {
                @include textBasis($text);
            }
            label {
                max-width: 640px;
            }
        }

        .img-wrapper {
            width: 35%;
            align-items: center;
            overflow: hidden;
            height: 300px;
            position: relative;
            img {
                position: absolute;
                justify-content: flex-end;
                width: 100%;
                animation: hideImg linear infinite 15s;
                opacity: 0;
                &:first-child {
                    opacity: 1;
                    animation: none;
                }
            }
            //
        }
    }
}

@include media-lg {
    #where-name-come-from {
        .where-name-come-from {
            position: relative;
            flex-wrap: wrap;
            justify-content: flex-end;
            .text-wrapper {
                width: 100%;
                min-height: 450px;
                height: unset;
                label {
                    max-width: unset;
                }
            }
    
            .img-wrapper {
                width: 100%;
                max-height: 270px;
                border: 1px solid white;
            }
        }
    }
    
}


@keyframes hideImg {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    55% {
        opacity: 1;
    }
    65%{
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
