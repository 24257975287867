@import "./../../../styles/main";
@import "./../../../styles/variables";

#intro-about-us {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50vh;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 130%;
        top: -30%;
        left: 0;
        @include backgroundPattern($DarkLavender,1.5);
        -webkit-transform: skew(0,4deg);
        -moz-transform: skew(0, 4deg);
        -ms-transform: skew(0, 4deg);
        transform: skew(0, 4deg);
    }
    .why-do-we-exist {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        position: relative;
        .textwrapper {
            position: absolute;
            width: 50%;
            max-width: 45rem;
            transition: left 0.5s;
            display: flex;
            flex-direction: column;
            justify-content: center;
            //padding: 0 30px 0 30px;
            
            .title {
                @include textBasisBold($pageTitle);
                color: white;
                padding-bottom: 18px;
            }
            .textpart{
                @include textBasis($pageSubtitle);
                color: white;
            }
            label{
                //align-self: center;
                max-width: 640px;
            }
            animation: showUp 0.5s linear forwards;
        }
    }
    
}


@include media-lg {
    #intro-about-us {
        .max-container{
            .textwrapper {
                .title {
                    @include textBasis(2.5rem);
                    color: white;
                }         
                
            }
        }
        
    }
}

@include media-md {
    #intro-about-us {
        .max-container{
            .textwrapper {
                width: 100%;
                position: relative;
                .title {
                    @include textBasis(2.5rem);
                    color: white;
                }         
                
            }
        }
        
    }
}