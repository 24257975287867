@import "../../../styles/variables";
@import "../../../styles/mixin";

.Frm {
    width: 100%;
    margin: 41px 40px 47px 45px;
    padding: 43px 55px 33px 68px;
    display: flex;
    flex-direction: row;
   // @include backgroundPattern($SeaSerpent, .8);
    @include backgroundPattern($AmarilloBanner,0.6,$OH-orange);
    box-shadow: 0px 10px 10px 2.3px rgba($BorderBlack, 0.15);
    border-radius: $btnBorderRadius;

    form{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .option-wrapper{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 15px 0;
        width: 100%;
        max-width: 1024px;
        
        label{
            white-space: wrap;
            min-width: 100px;
            height: 55px;
            margin: 0 25px 0 0;
            @include textBasisBold($text);
            align-items: flex-start;
            display: flex;
        }

        
        .wrapper{
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;
            align-items: center;
            justify-content: center;
            padding-bottom: 25px;
            input{                
                height: 35px;
            }
            textarea{
                height: 265px;
                resize: vertical;
            }
            input, textarea {
                width: 100%;
                margin: 0;
                border-radius: $borderRadiusMain;
                border: solid $BorderWidth rgba($BorderBlack,0.5);                
                @include textBasis($text);
                padding-left: 18px;
            }
            &.error{
                
                input{
                    border: solid 2px $CeriseRed;
                }
                textarea{
                    border: solid 2px $CeriseRed;
                }
                .error{
                    text-align: right;
                    @include textBasis($text);
                    color: $CeriseRed;
                    position: absolute;
                    bottom: 5px;
                    right: 0;
                }
            }
            

            .radiobutton-group{
                display: flex;
                flex-direction: row;

                .radiobutton{
                    height: 15px;
                    width: 25px;
                }
            }
            
            
        }
    }

    button{
        width: 207px;
        background-color: $CeriseRed;
        align-self: center;
    }
    button:hover{
        background-color: darken($CeriseRed,$darkerPorcentaje);
    }
}

@include media-md{
    .Frm {
        width: 100%;
        margin: 20px 20px 23px 22px;
        padding: 21px 27px 16px 34px;
        .option-wrapper{
            margin: 5px 0;
            .wrapper{
                input{
                    height: 35px;
                    //margin: 0 0 10px 0;
        
                    font-size: 17px;
                    font-weight: lighter;
                    font-stretch: lighter;
                    font-style: lighter;
                    letter-spacing: lighter;
                    text-align: left;
                    //padding-left: 18px;
                }
            }
        }
        
        button{
            color: $White;
            width: 103px;
            height: 29px;
        }
    }
}


@include media-sm{
    .Frm {
        .option-wrapper{
            .wrapper{
                input{
                    height: 25px;
                   // margin: 0 0 10px 0;
                    font-size: 11px;
                    //padding-left: 10px;
                    
                }
                padding-bottom: 0;
            }
        }
    }
}