@import "../../styles/mixin";
@import "../../styles/variables";


$distance: 17px;
#contact {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height:100vh;
    padding-top: $spaceBetween;
    &::before {
        content: '';
        position: absolute;
        top: -10%;
        left: 0;
        width: 100%;
        height: 100%;
        @include backgroundPattern($CeriseRed, 1.4, $CeriseRed, $MinionYellow, $DarkLavender, $CeriseRed);
        -webkit-transform: skew(0,-184deg);
        -moz-transform: skew(0, -184deg);
        -ms-transform: skew(0, -184deg);
        transform: skew(0, -184deg);
    }
    .contact {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin-bottom: 20px;
        z-index: 1;
        .title {
            display: flex;
            flex-direction: column;
            padding-top: 17px;
            margin-bottom: $distance;
            text-align: center;
            @include textBasis($subtitle);
            color: white;
            :first-child {
                text-align: center;
                margin-bottom: 12px;
                @include textBasisBold($pageTitle);
                color:white
            }
        }
        .form-wrapper {
            justify-content: center;
            width: 100%;
            .contact-options-wrapper {
                display: flex;
                width: 100%;
                padding-bottom: 15px;
                align-items: end;
                justify-content: space-between;
                .contact-option-card {
                    cursor: pointer;
                    display: flex;
                    flex: 1 1;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    text-align: center;
                    padding-top: 15px;
                    padding-bottom: 10px;
                    height: 172px;
                    max-width: 300px;
                    background-color: $DarkLavender;
                    @include backgroundPattern($AmarilloBanner,0.6,$OH-orange);
                    border-radius: $btnBorderRadius;
                    box-shadow: 0px 10px 10px 2.3px rgba($BorderBlack, 0.15);
                    position: relative;                
                    margin-bottom: 0;
                    transition: all ease 0.3s;
                    .contact-option-title {
                        label {
                            @include textBasisBold($text);
                        }
                    }
                    &.selected {
                        border-radius: $btnBorderRadius $btnBorderRadius 0 0; 
                        box-shadow: 0px -10px 10px 2.3px rgba($BorderBlack, 0.15);
                        margin-bottom: -15px;
                    }
                    .contact-option-img {
                        height: 70px;
                        img {
                            height: 100%;
                        }
                    }
                }
            }
    
            .contact-form {
                justify-self: center;
                flex-wrap: wrap;
                justify-content: center;
                display: flex;
                width: 100%;
                border-radius: $btnBorderRadius;
                box-shadow: 0px 10px 10px 2.3px rgba($BorderBlack, 0.15);
                margin-top: 35;
                &.border-radius-left {
                    border-radius: 0  $btnBorderRadius $btnBorderRadius $btnBorderRadius;
                }

                &.border-radius-right {
                    border-radius: $btnBorderRadius   0 $btnBorderRadius $btnBorderRadius;
                }
            }
            .contact-form, .contact-options-wrapper .contact-option-card.selected {
                background: rgba($White,1);
            }
        }
        .modal {
            position: fixed;
            width: 100%;
            height: 100vh;
            background-color: #00000082;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            z-index: 9999;
            .dialog{
                position: relative;
                width: 300px;
                height: 300px;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: $btnBorderRadius;
                border: solid $BorderWidth $BorderBlack;
                padding: 15px;
                text-align: center;
                box-sizing: content-box;
                @include textBasis($text);
                .close {
                    position: absolute;
                    right: 10px;
                    top:10px;
                    font-size: 1.5rem;
                    cursor: pointer;
                }
            }
            img {
                width: 300px;
            }
            
        }
    }
    
}

@include media-lg {
    #contact {
        .contact {
            padding: 0 $mainMDRightLeftPadding;
            .form-wrapper {
                .contact-form {
                    .option-wrapper {
                        label {
                            width: 0px;
                            min-width: 0;
                            margin-right: 0;
                            visibility: hidden;
                            position: absolute;
                        }
                    }
                }
    
                .contact-options-wrapper {
                    .contact-option-card {
                        height: 176px;
                        max-width: 190px;
                       
                    }
                }
            }
        }
    }
    
}
@include media-md {
    #contact {
        .contact {
            padding: 0 $mainMDRightLeftPadding;
            .form-wrapper {
                .contact-form {
                    .option-wrapper {
                        label {
                            width: 0px;
                            min-width: 0;
                            margin-right: 0;
                            visibility: hidden;
                            position: absolute;
                        }
                    }
                }
    
                .contact-options-wrapper {
                    .contact-option-card {
                        height: 176px;
                        max-width: 160px;
                        .contact-option-img {
                            img {
                                width: 90%;
                                height: 90%;
                            }
                        }
                        .contact-option-title {
                            label {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    
}
@include media-sm {
    #contact {
        .contact {
            .form-wrapper {
                .contact-options-wrapper {
                    .contact-option-card {
                        height: 70px;
                        max-width: 70px;
                        position: relative;
                        .contact-option-img {
                            img {
                                max-height: 50px;
                                max-width: 50px;
                            }
                        }
                        .contact-option-title {
                            position: absolute;
                            opacity: 0;
                            bottom: 70px;
                            left: 0;
                            label {
                                @include textBasisBold(0.6rem);
                                line-height: 0.2rem;
                                color: white;
                                font-family: Montserrat-Bold;
                            }
                        }
                        &.selected {
                            .contact-option-title{
                                opacity: 1;
                            }                    
                        }
                    }
                }
            }
        }
    }
    
}

