@import "./../../../styles/main";
#our-history{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        @include backgroundPattern($CeriseRed, 1.4, $CeriseRed, $MinionYellow, $DarkLavender, $CeriseRed);
        transform: skew(0, -1deg);
    }
    .our-history{
        display: flex;
        flex-direction: column;
        padding-top: $spaceBetween45;
        max-width: 100%;
        margin-bottom: 76px;
        .title{
            width: 100%;
            @include textBasisBold($title);
            color: white;
            text-align: center;
            z-index: 1;
        }
    
        .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
            display: none;
        }
    }
}


@include media-md{
    .our-history{
        padding: 0 $mainMDRightLeftPadding;
    }
}