@use 'sass:math';
@import "./../../../styles/variables";
@import "./../../../styles/mixin";

#numbers{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: $spaceBetween45 0;
    position: relative;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: " ";
        @include backgroundPattern($SeaSerpent, 0.8);
        width: 100%;
        height: 100%;
        transform: skew(0,1deg);
    }
    .max-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        z-index: 1;
        flex-wrap: wrap;
        .element {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: math.div($spaceBetween45, 2);
            max-width: 280px;
            .element-number {
                @include textBasis(4*$title);
                color:white;
                span {
                    @include textBasis(4*$title);
                    color:white;
                }
            }
            .element-title {
                @include textBasisBold($title);
                color:white;
                margin-bottom: 10px
            }
            .element-text {
                @include textBasis($text);
            }
            .element-title, .element-text,.element-number {
                text-align: center;
            }
        }
    }
}